<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="parent_id">Account:</label>
        <Select2 name="account_id" required v-model.number="vehicle_sharing.account_id" :options="accountOptions" @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Vehicle:</label>
        <Select2 name="vehicle_id" required v-model.number="vehicle_sharing.vehicle_id" :options="vehicleOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Title:</label>        
        <input class="form-control" name="name" type="text" required v-model="vehicle_sharing.name">
      </div>
      <div class="form-group form-check col-sm-12">
        <input type="checkbox" class="form-check-input" id="use_expiration" v-model="is_checked">
        <label class="form-check-label" for="use_expiration">Use Expiration</label>
      </div>
      <div v-if="is_checked == true" class="form-group col-sm-6">
        <label for="expiration_date">Expired Date:</label>
        <input class="form-control" name="expiration_date" type="date" v-model="expiration_date">
      </div>
      <TimeComponent v-if="is_checked == true" :label="'Expired Time'" :selected_time="expiration_time" v-on:selected-time="setTime"/>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/vehicle_sharings">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  import TimeComponent from '../components/TimeComponent.vue'
  import moment from 'moment'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('vehicle_sharings', ['onRequest']),
    },
    data(){
      return {
        vehicle_sharing: {
          account_id: '',
          vehicle_id: '',
          name: '',
          expiration_date: null,
        },
        expiration_date: moment().add(1, 'day').format("YYYY-MM-DD"),
        expiration_time: '07:00',
        is_checked: true,
        accountOptions: [],
        vehicleOptions: [],
      }
    },
    components: {
      Select2,
      TimeComponent,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.vehicle_sharing.expiration_date = null
        if(this.is_checked == true){
          this.vehicle_sharing.expiration_date = this.expiration_date + " " + this.expiration_time
        }
        this.add(this.vehicle_sharing)
      },
      onChangeAccount(event){
        this.get_vehicle_by_account({account_id: parseInt(event.id)})
        this.vehicle_sharing.vehicle_id = ''
      },
      setTime(val){
        this.expiration_time = val
      },
      ...mapActions('vehicle_sharings', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', ['get_vehicle_by_account']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Add New Vehicle Sharing')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no})
            })
          }
          return
        }
      },
    }
  }
</script>
