<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="route_id">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="vehicle_route.vehicle_id" :options="vehicleOptions"  @change="onChange($event)" @select="onChange($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="route_id">Route:</label>
        <Select2 name="route_id" v-model.number="vehicle_route.route_id" :options="routeOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/vehicle_routes">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('vehicle_routes', ['onRequest']),
        ...mapState('routes', ['routes']),
        ...mapState('vehicles', ['vehicles']),
    },
    data(){
      return {
        vehicle_route :{
          route_id : '',
          vehicle_id : '',
        },
        routeOptions: [],
        vehicleOptions: [],
        }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.add(this.vehicle_route)
      },
      ...mapActions('vehicle_routes', ['add']),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
      ...mapActions('vehicles', {getVehicles: 'get_all'}),
      onChange(event){
        let vehicle = this.vehicles.filter(item => item.id == event.id)
        if(vehicle.length > 0){
          this.getRoutes({account_id: parseInt(vehicle[0].account_id)})
          this.route_id = ''
        }
      },
    },
    created() {
      this.$emit('onChildInit', 'Edit Vehicle')
      this.getVehicles()
    },
    watch: {
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name + ' - ' + item.route_code})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
    }
  }
</script>
