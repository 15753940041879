<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="vehicle_route.vehicle" class="form-group col-sm-12 col-md-12">
          <label for="vehicle_no">Vehicle No:</label>
          <input class="form-control" disabled name="vehicle_no" type="text" v-model="vehicle_route.vehicle.vehicle_no">
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="route_id">Route:</label>
        <Select2 name="route_id" v-model.number="vehicle_route.route_id" :options="routeOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/vehicle_routes">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('vehicle_routes', ['vehicle_route', 'onRequest']),
        ...mapState('routes', ['routes']),
    },
    data(){
      return {
        routeOptions: [],
      }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.vehicle_route)
      },
      ...mapActions('vehicle_routes', ['edit', 'get']),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
    },
    created() {
      this.$emit('onChildInit', 'Edit Vehicle')
      this.get(this.$route.params.id)
    },
    watch: {
      vehicle_route (nextState, prevState) {
        if(nextState !== prevState){
          this.getRoutes({account_id: nextState.account_id})
          return
        }
      },
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name + ' - ' + item.route_code})
            })
      }
          return
        }
      },
    },
  }
</script>
