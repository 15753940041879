<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-3" v-for="cap in user_capability" :key="cap">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" :id="cap.id" v-model="cap.is_able">
          <label class="form-check-label" :for="cap.id">{{cap.capability}}</label>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/users">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('user_capabilities', ['user_capability', 'onRequest']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit({user_id: parseInt(this.$route.params.id), capabilities: this.user_capability})
      },
      ...mapActions('user_capabilities', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit User Role')
      this.get(this.$route.params.id)
    }
  }
</script>
