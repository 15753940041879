<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="from_date">From Date:</label>
            <input class="form-control" required type="date" v-model="from_date">
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="to_date">To Date:</label>
            <input class="form-control" required type="date" v-model="to_date">
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="data" :columns="columns" :title="'Work Order'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('work_orders', ['work_orders', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
          {name: 'start', text: 'Start', order: 'desc', raw:{type:'Moment_UTC'}},
          {name: 'work_order_code', text: 'Work Order Code'},
          {name: 'account', text: 'Account',
            raw: {
              type: 'Sub',
              value: 'account_name'
            }
          },
          {name: 'vehicle', text: 'Vehicle',
            raw: {
              type: 'Sub',
              value: 'vehicle_no'
            }
          },
          {name: 'status', text: 'Status',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'Pending'},
                {value: '2', text: 'Started'},
                {value: '3', text: 'On Progress'},
                {value: '4', text: 'Completed'},
              ]
            }
          },
          {name: 'service_by', text: 'Service By',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: '3rd Party / Vendor'},
                {value: '2', text: 'Own Workshop'},
              ]
            }
          },
          {name: 'advisor', text: 'Advisor'},
          {name: 'mechanic', text: 'Mechanic'},
          {name: 'type', text: 'Type',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'Service'},
                {value: '2', text: 'Inspection'},
                {value: '3', text: 'Repair'},
                {value: '4', text: 'Others'},
              ]
            }
          },
          {name: 'updated_at', text: 'Last Update', raw :{type: 'Moment_UTC'}},                    
          {name: 'cost', text: 'Non Part Cost', class: 'text-right',
            raw: {
              type: 'Sub_format_number',
              value: 'total_cost'
            }
          },     
          {name: 'cost', text: 'Non Part Cost Status',
            raw: {
              type: 'Sub_text',
              value: 'status',
              fields: [
                {value: '0', text: 'Need Approval'},
                {value: '1', text: 'Approved'},
                {value: '2', text: 'Paid'},
              ]
            }
          },                    
          {name: 'inventory_cost', text: 'Part Usage', class: 'text-right',
            raw: {
              type: 'format_number'
            }
          },     
          {name: 'total_cost', text: 'Total Cost', class: 'text-right',
            raw: {
              type: 'format_number'
            }
          },    
          {name: 'maintenance_schedule', text: 'Maintenance Schedule Name',
            raw: {
              type: 'Sub',
              value: 'maintenance_name'
            }
          },    
          {name: 'maintenance_schedule', text: 'Estimation Cost', class: 'text-right',
            raw: {
              type: 'Sub_format_number',
              value: 'cost_estimation'
            }
          },       
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                getCapability('edit_work_order', this.user) ? {event: 'edit_work_order', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                getCapability('delete_work_order', this.user) ? {event: 'work_orders/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                {event: 'print_work_order', text: 'Print', method: 'print-work-order', class: 'btn btn-info ml-1'},
              ]
            }
          },
        ]
      }
    },
    data(){
      return {
        from_date: moment().subtract(30, 'days').format("YYYY-MM-DD"),
        to_date: moment().add(30, 'days').format("YYYY-MM-DD"),
        account_id: null,
        accountOptions: [],
        data: []
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('work_orders', {getData: 'get_work_order_by_account'}),
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('accounts', ['get_all']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {from_date, to_date, account_id} = this
        let day = moment(from_date).diff(moment(to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }
        this.getData({from_date, to_date, account_id})
      }
    },
    created() {
      this.$emit('onChildInit', 'Work Order', getCapability('add_work_order', this.user) ? '/work_orders/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
      work_orders(nextState, prevState){
        if(nextState !== prevState){
          this.data = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.data.push({...item, ...{total_cost: item.inventory_cost + (item.cost ? item.cost.total_cost : 0)}})
            })
          }
          return
        }
      },
    }
  }
</script>
