<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="from_date">From Date:</label>
            <input class="form-control" required type="date" v-model="from_date">
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="to_date">To Date:</label>
            <input class="form-control" type="date" v-model="to_date">
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="vehicle_sharings" :columns="columns" :currEntries="50" :title="'Vehicle Sharing User'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import { getCapability } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('vehicle_sharings', ['vehicle_sharings', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'expiration_date', text: 'Expired Date', order: 'desc', raw:{type:'Moment'}},
            {name: 'name', text: 'Title'},
            {name: 'account', text: 'Account',
              raw: {
                type: 'Sub',
                value: 'account_name'
              }
            },
            {name: 'vehicle', text: 'Vehicle',
              raw: {
                type: 'Sub',
                value: 'vehicle_no'
              }
            },
            {name: 'url', text: 'URL'},
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('delete_vehicle_sharing', this.user) ? {event: 'vehicle_sharings/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
        }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().add(7, 'day').format("YYYY-MM-DD"),
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id, from_date, to_date} = this
        this.get_vehicle_sharing_by_account({account_id, from_date, to_date})
      },
      ...mapActions('vehicle_sharings', ['get_vehicle_sharing_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Vehicle Sharings', getCapability('add_vehicle_sharing', this.user) ? '/vehicle_sharings/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
    }
  }
</script>
